$primary-color: #3a3b7b;
$secondary-color: #30ecac;
.nav-container {
  height: 71px;
  border-right: 4px solid #4c94d3;
  border-left: 4px solid #4c94d3;
  background-color: #fff;
  z-index: 1;
  position: relative;
  list-style-type: none;
  .login-mobile {
    background: linear-gradient(270deg, #35d9b3 1.47%, #4b98d1 98.53%);
    border-radius: 8px;
    box-shadow: 0px 4px 12px 0px #00000033;
    margin-bottom: 10px;
  }
  .signUp-mobile {
    background: #4185f4;
    box-shadow: 0px 4px 12px 0px #00000033;
    border-radius: 8px;
  }

  // @media only screen and (max-width: 640px) {
  //   .menu-mob-offcanvas {
  //     width: 254px;
  //     li {
  //       width: 100%;
  //       height: 35px;
  //       font-weight: 500;
  //       font-size: 1rem;
  //       color: #3a3b7b !important;
  //       background-color: #eafdf7;
  //       margin-bottom: 12px;
  //     }
  //     a {
  //       width: 100%;
  //       height: 35px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   }
  //   .active {
  //     color: #35cfc7;
  //     font-weight: bold;
  //     width: 100% !important;
  //     height: 35px;
  //   }
  //   .social {
  //     direction: ltr;
  //     font-size: 20px;
  //     position: absolute;
  //     bottom: 50px;

  //     div {
  //       background-color: #38287c;
  //       margin-left: 5px;

  //       &:hover {
  //         background-color: #00f2ea;
  //         color: #38287c;
  //       }
  //     }
  //   }
  // }
}
.nav-bar {
  width: 789px;
  height: 18px;
  list-style-type: none;
  li {
    display: inline-block;
    list-style-type: none;
    font-weight: 500;

    .nav-links {
      text-decoration: none;
      color: $primary-color;
      padding-bottom: 21px;
      &:hover {
        color: $secondary-color;
        text-decoration: none;
        border-bottom: 4px solid $secondary-color;
        // font-weight: bold;
        // transition: ease 0.5;
      }
    }
    .active {
      color: #35cfc7;
    }
  }
}
.side-buttons {
  // width: 345px;

  height: 43px !important;
  font-weight: 700;
  .sign-up {
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 12px 0px #00000033;
  }
  .sign-in {
    background: linear-gradient(273.98deg, #30ecac -19.11%, #4c94d3 101.21%);

    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 12px 0px #00000033;
  }
}
.dropdownContanier {
  .userLoggedIn {
    color: #35cfc7 !important;
    font-weight: bold;
  }
  li,
  .menuitem {
    font-weight: 500;

    button {
      color: #3a3b7b;
      font-weight: 500;
      svg {
        margin: 1px;
        margin-left: 3px;
      }
    }
  }
}
@media only screen and (max-width: 640px) {
  .menu-mob-offcanvas {
    width: 254px;
    li {
      width: 100%;
      height: 35px;
      font-weight: 500;
      font-size: 1rem;
      color: #3a3b7b !important;
      background-color: #eafdf7;
      margin-bottom: 12px;
    }
    a {
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .active {
    color: #35cfc7;
    font-weight: bold;
    width: 100% !important;
    height: 35px;
  }
  .social-offcanvas {
    direction: ltr;
    font-size: 20px;
    position: absolute;
    bottom: 10px;

    div {
      background-color: #38287c;
      margin-left: 5px;

      &:hover {
        background-color: #00f2ea;
        color: #38287c;
      }
    }
  }
}
