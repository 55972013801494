.header {
  background-image: url("../../assets/images/headercontact-min.png");
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    background-image: url("../../assets/images/headerlgp.png") !important;
  }
  @media screen and (max-width: 1024px) {
    background-image: url("../../assets/images/headermobp.png") !important;
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  width: 100%;
  color: #fff !important;
  margin-top: 66px;
  h1 {
    font-size: 4rem;
    font-weight: bolder;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 40px !important;
    }
    @media screen and (min-width: 640px) and (max-width: 1024px) {
      font-size: 25px !important;
    }
    @media screen and (max-width: 640px) {
      font-size: 18px !important;
    }
  }
  a {
    font-size: 1.5rem;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 20px !important;
    }
    @media screen and (min-width: 640px) and (max-width: 1024px) {
      font-size: 14px !important;
    }
    @media screen and (max-width: 640px) {
      font-size: 11px !important;
    }
    font-weight: 700;
    &:hover {
      color: #5cdcd0;
    }
  }
}

.custom-scrollbar-container-layout {
  // height: 300px; /* Set the desired height */
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent; /* Firefox */
  cursor: pointer;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: 1px solid #f1f1f1;
    /* Set the color of the scrollbar thumb */
    border-radius: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Set the color of the scrollbar track */
    margin-block: 0.5em;
  }
}
.scroll-watcher {
  height: 10px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #5cdcd0;
  scale: 0 1;
  transform-origin: right;
  animation: scroll-watcher 2s linear forwards;
  animation-timeline: scroll();
  z-index: 10000;
}

@keyframes scroll-watcher {
  to {
    scale: 1 1;
  }
}
