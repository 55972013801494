footer {
  background-color: #f4fcfe;
  width: 100%;
  background-image: url("../../assets/images/footerbackground.png");
  /* Other background properties can be added as needed */
  background-size: cover; /* or 'contain' depending on your preference */
  background-repeat: no-repeat;
  // background-attachment: fixed;
  // margin-top: 100px;
  position: relative;
  bottom: 0;
  padding: 20px;
  .links-footer {
    color: #1a2562;
  }
  li {
    font-size: 0.9rem;
    font-weight: 400;
  }
  .logo-footer {
    background-color: #caf0f6;
    width: 100%;
    height: 64px;
    margin-bottom: 20px;
    padding-right: 53px;
  }
  .send-mail {
    /* Apply this style to remove focus outline */
    &:focus {
      outline: none;
    }

    /* Add a more accessible focus style as an alternative */
    &:focus {
      box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5);
    }
  }
  .subscription-btn {
    background: linear-gradient(90deg, #25c0cb 0%, #30edae 81.4%);
    border-radius: 91px;
  }
  .social {
    direction: ltr;
    float: right;
    font-size: 20px;
    div {
      background-color: #38287c;
      &:hover {
        background-color: #00f2ea;
        color: #38287c;
      }
    }
  }
}
