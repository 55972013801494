.background-verify-page {
  background-color: #eefbf9;
  margin-top: -42px;
  border-bottom: 10px solid #2d8be2;
  .background-vefiy {
    background-image: url("../../../assets/images/registerback.svg");
    background-repeat: no-repeat;
    height: 539px;
    position: relative;
    margin-top: 110px;
    width: 477px;
    img {
      position: absolute;
      top: -150px;
      right: 48px;
    }
  }
  .info {
    // margin-top: 20px;
    .membership {
      border: 0.7px solid #caddf4;
      border-radius: 12px;
      height: 50px;
      // width: 525px;
      &::placeholder {
        color: #798da7;
        font-size: 1rem;
      }
    }
  }
  .membership-verify {
    margin-top: 22px;
    button {
      height: 50px;
      border-radius: 12px;
      &:disabled {
        background-color: #d3d3d3 !important; /* Light gray background for disabled state */
        color: #a9a9a9; /* Light gray text color for disabled state */
        cursor: not-allowed; /* Show not-allowed cursor for disabled state */
      }
      &:enabled {
        background: linear-gradient(271.73deg, #30ecac 0.61%, #4c94d3 110.25%);
      }
    }
  }
}
