.background {
  background-image: url("./assets/images/Rectangle 2594.svg");
  @media screen and (max-width: 640px) {
    background-image: none;
  }
  /* Other background properties can be added as needed */
  background-size: cover; /* or 'contain' depending on your preference */
  background-repeat: no-repeat;
  // margin-top: -200px;
  // background-attachment: fixed;
}
html,
body {
  height: 100%;
  margin: 0;
}
.custom-scrollbar-container{
  @media screen and (max-width: 640px) {
    overflow-x: hidden;;
  }
  
}