.background-login {
  // background-color: #eefbf9;
  // margin-top: -42px;
  border-bottom: 10px solid #2d8be2;
  width: 100%;

  background-image: url("../../../assets/images/loginbackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}
.Login {
  // padding: 100px 30px;

  h2 {
    color: #1e2865;
  }
  .sign-in {
    background-color: #fff;
    box-shadow: 3px 3px 12px 0px #0000001a;
    color: #435469 !important;
    border-radius: 12px;
    // width: 632px;
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    button {
      background-color: transparent;
      cursor: pointer;
      border-bottom: 0;
      box-shadow: none !important;
      border: none;

      span {
        color: #435469 !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        box-shadow: none !important;
      }
    }
    // border: 0.7px solid #caddf4 !important;
  }
  .name-shadow {
    box-shadow: 0px 3px 0px 0px #ee4c4280 !important;
  }
  .login-form {
    .input-login,
    .select-login {
      box-shadow: 0px 3px 0px 0px #ee4c4280;
      background-color: #fff;
      box-shadow: 3px 3px 12px 0px #0000001a;
      color: #435469;
      border-radius: 12px;
      border: 0.7px solid #caddf4 !important;
      height: 50px;
    }
    .input-rigister {
      padding: 17px 27px 14px;
    }
    .submit-btn {
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-bottom: 10px;
      border-radius: 12px;
      &:disabled {
        background-color: #d3d3d3 !important; /* Light gray background for disabled state */
        color: #a9a9a9; /* Light gray text color for disabled state */
        cursor: not-allowed; /* Show not-allowed cursor for disabled state */
      }
      &:enabled {
        background: linear-gradient(271.73deg, #30ecac 0.61%, #4c94d3 110.25%);
      }
    }
  }
  .background-login-bottom {
    // background-image: url("../../../assets/images/registerback.svg");
    @media screen and (max-width: 1023px) {
      // background-image: url("../../../assets/images/mobileLogin.svg");
      height: 418px;
    }
    height: 502px;
    position: relative;
    // margin-top: 110px;
    img {
      position: absolute;
      top: 98px;
      right: 86px;
      @media screen and (max-width: 1024px) {
        right: 0;
      }
    }
  }
}
