.order-detalis {
  border: 1px solid #bbedec;
  //   background-color: #ffffff66;
  background-color: transparent;
  border-radius: 19px;
  padding: 30px 20px;
}
.paid-detlis {
  border: 1px solid #bbedec;
  background-color: #ffffff66;
  border-radius: 19px;
  padding: 30px 20px;
}
.table-heading {
  border-bottom: 1px solid #c9f2ef;
}
.last-row {
  border-top: 1px solid #bbedec;
  margin-top: 10px;
}
.payment-button {
  box-shadow: 0px 10px 25px 0px #bbebe7;
  background: linear-gradient(94.48deg, #24b9d1 -8.56%, #30ecac 93.91%);
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  height: 64px;
  border-radius: 100px;
  //   margin-top: 20px;
}
.payment-button-apple {
  box-shadow: 0px 10px 25px 0px #bbebe7;
  background: linear-gradient(94.48deg, #24b9d1 -8.56%, #30ecac 93.91%);
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  height: 64px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  //   margin-top: 20px;
}
