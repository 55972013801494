.card-orders {
  box-shadow: 0px 4px 15px 0px #dff2f5;
  &:nth-child(2) {
    box-shadow: 0px 12px 40px 0px #acd1f199;
    z-index: 2;
  }
  &:nth-child(1) {
    margin-left: -10px;
  }
  &:nth-child(3) {
    margin-right: -10px;
  }
  padding: 30px;
  border-radius: 25px;
  .features-orders {
    border-bottom: 2px solid #eee;
    .send-req {
      box-shadow: 0px 0px 0px 4.45px #c9f2ef;
      background-color: #4185f4;
      margin-top: 20px;

      border-radius: 100px;
    }
    .send-middel {
      background: linear-gradient(180deg, #30ecac 0%, #4c94d3 100%) !important;
      box-shadow: 0px 0px 0px 4.45px #c9f2ef66 !important;
    }
  }
}
.req-card {
  background-image: url("../../../assets/images/backgroundOrders.png");
  background-size: cover;
  background-repeat: no-repeat;
}
