.accordion {
  width: 100%;

  height: fit-content;
  background-color: #f6feff;
  box-shadow: 0px 4px 20px 0px #e8f4f3;
  border: 2px solid #e9f3f6;

  border-radius: 6px;
  color: #395185;
  cursor: pointer;
  margin-bottom: 20px;

  .btn-container {
    width: 30px;
    height: 33px;
    background-color: #2ad2c1;
    position: relative;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 20px;

    .accordion-btn {
      color: #fff !important;
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.accordion-open {
  width: fit-content;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  height: fit-content;
  border-radius: 6px;
  border-width: 2px;
  background-color: #e9f3f6;
  line-height: 18px;
  P {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    padding: 0 10px;
    font-weight: bold;
  }
}
