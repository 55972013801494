// .success-contaier {
//   background-image: url("../../../../assets/images/successbackground.png");
//   background-repeat: no-repeat;
//   background-color: none;
// }
.btn-success {
  width: 100%;
  height: 50px;
  background: linear-gradient(270.53deg, #30ecac 9.07%, #4c94d3 104.53%);
  font-size: 20px;
  font-weight: bold;
  border-radius: 12px;
  color: #fff;
}
