@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
$primary-color: #3a3b7b;
$secondary-color: #30ecac;
$heading: #0e2d60;
$secondary2-color: #586475;
html {
  direction: rtl;
}
// button:disabled {
//   background-color: #ace3e4 !important; /* Light gray background for disabled state */
//   color: #ffff; /* Light gray text color for disabled state */
//   cursor: not-allowed; /* Show not-allowed cursor for disabled state */
// }
.primary {
  color: $primary-color !important;
}
.secondary {
  color: $secondary-color !important;
}
.secondary-2 {
  color: $secondary2-color;
}
.bg-gradiant {
  background: linear-gradient(270.53deg, #30ecac 9.07%, #4c94d3 104.53%);
}
.heading-img {
  margin: 0 auto;
  margin-top: -65px;
}
body {
  // min-height: 100vh;
  overflow-y: scroll;
  color: $primary-color;
  margin: 0;
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background-color: #f1f9f9;
  // overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[checkbox] {
  &:focus {
    --tw-ring-color: none;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;
  }
}
.Toastify__toast--error {
  background: #f8d7da !important;
  font-size: 20px;
  width: 500px;
  @media screen and (max-width: 640px) {
    font-size: 16px;
  width: 320px;
  }
  text-align: center;
  color: #721c24 !important;
}
.Toastify__toast--success {
  background: #d4edda !important;
  font-size: 25px;
  width: 500px;
  text-align: center;
}
/* CustomScrollBar.css */
// scrollbar-width: thin !important, /* Firefox */
// scrollbar-color: #3498db #f1feff !important /* Firefox */
// cursor: pointer;
::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(143, 168, 163, 0.5) !important;
  border: 1px solid #f1f1f1 !important;
  /* Set the color of the scrollbar thumb */
  border-radius: 14px !important;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important; /* Set the color of the scrollbar track */
  // margin-block: 0.5em !important;
}
// .custom-scrollbar-container {
//   background: url("../src/assets/images/body.svg");
//   background-size: cover;
//   background-repeat: repeat;
//   overflow-y: auto;
//   z-index: 1;
//   position: relative;
//   overflow-x: hidden;
//   scrollbar-width: thin !important; /* Firefox */
//   scrollbar-color: rgba(143, 168, 163, 0.5) !important; /* Firefox */
//   // cursor: pointer;
//   &::-webkit-scrollbar {
//     width: 10px !important;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: linear-gradient(
//       225.31deg,
//       #ffffff 53.34%,
//       rgba(255, 255, 255, 0) 70.24%
//     ) !important;
//     border: 1px solid #f1f1f1 !important;
//     /* Set the color of the scrollbar thumb */
//     border-radius: 14px !important;
//   }

//   &::-webkit-scrollbar-track {
//     background: linear-gradient(
//       225.31deg,
//       #ffffff 53.34%,
//       rgba(255, 255, 255, 0) 70.24%
//     ) !important; /* Set the color of the scrollbar track */
//     // margin-block: 0.5em !important;
//   }
// }
.no-data {
  p {
    -webkit-text-stroke: 1px #2ddcbb;
    color: #0e2d60;
  }
}
html,
body {
  height: 100%;
  margin: 0;
}
