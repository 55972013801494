.background-verified-page {
  background-color: #eefbf9;
  margin-top: -42px;
  border-bottom: 10px solid #2d8be2;
  .background-verfied {
    background-image: url("../../../assets/images/registerback.svg");
    background-repeat: no-repeat;
    height: 539px;
    position: relative;
    margin-top: 110px;
    width: 477px;
    img {
      position: absolute;
      top: -150px;
      right: 120px;
      width: 264px;
      height: 262px;
    }
  }
  .info-verified {
    margin-top: 115px;
    margin-right: 30px;
    .membership {
      border: 0.7px solid #caddf4;
      border-radius: 12px;
      height: 50px;
      width: 525px;

      &::placeholder {
        color: #798da7;
        font-size: 1rem;
      }
    }
  }
  .btn-verify {
    margin-top: 22px;
    button {
      background: linear-gradient(270.53deg, #30ecac 9.07%, #4c94d3 104.53%);
      height: 50px;
      border-radius: 12px;
    }
  }
}
